import axios from "axios";
import {TRAJETS_API} from "../../config";
import Cache from "../cache";

async function findAll() {
    const cachedTrajets = await Cache.get("AdminTrajets");
    if(cachedTrajets) return cachedTrajets;
    return axios
        .get(TRAJETS_API)
        .then(response => {
            const trajets = response.data.trajets;
            Cache.set("AdminTrajets", trajets);
            return trajets;
        });
}


async function find(id){

    const cachedTrajets = await Cache.get("AdminTrajets." + id);
    if(cachedTrajets) return cachedTrajets;
    return axios.get(TRAJETS_API + "/" + id)
        .then(response => {
            const trajet = response.data.trajet;
            Cache.set("AdminTrajets." + id, trajet);
            return trajet;
        });
}


function deleteTrajet(id) {

    return axios
        .delete(TRAJETS_API + "/" + id)
        .then(async response => {
            const cachedTrajets = await Cache.get("trajets");

            if(cachedTrajets){
                Cache.set("AdminTrajets", cachedTrajets.filter(i => i.id != id));
            }

            return response;
        });
}

function updateTrajet(id, trajet){
    axios.defaults.headers.common['Content-Type'] = "multipart/form-data";
    return axios.post(
        TRAJETS_API + "/store",
        {...trajet, id: id}
    )
        .then(async response => {
            const cachedTrajets = await Cache.get("AdminTrajets");

            const cachedTrajet = await Cache.get("AdminTrajets." + id);

            if(cachedTrajet){
                Cache.set("AdminTrajets." + id, response.data.trajet);
            }

            if(cachedTrajets){
                const index = cachedTrajets.findIndex(b => b.id === +id);
                cachedTrajets[index] = response.data.trajet;
            }

            return response;
        });
}

function create(trajet){

    return axios.post(TRAJETS_API + "/store", trajet)
        .then(async response => {
            const cachedTrajets = await Cache.get("AdminTrajets");

            if(cachedTrajets){
                Cache.set("AdminTrajets", [...cachedTrajets, response.data.trajet]);
            }

            return response;
        });
}

export default {
    findAll,
    find,
    updateTrajet,
    create,
    delete : deleteTrajet,
};