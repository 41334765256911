import React, { useEffect, useState } from 'react';
import {Link, useParams} from 'react-router-dom';
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import TrajetAPI from "../../../apis/adminApi/trajetsAPI";
import FormContentLoader from "../../loaders/FormContentLoader";
import Field from "../../forms/Field";
import MyButton from "../../assets/MyButton";
import CheckBoxField from "../../forms/CheckBoxField";

const EditTrajet = () => {

    const [showLoader, setShowLoader] = useState(false);
    const { formState } = useForm();
    const navigate = useNavigate();
    const { isSubmitting } = formState;
    const [loading, setLoading] = useState(true);
    let {trajetId} = useParams();
    const [checked, setChecked] = useState(false);

    const [trajet, setTrajet] = useState({
        from : "",
        to : "",
        price : "",
        price_fret : 0,
        newest : "",
        image : "",
        id : trajetId
    });

    const [editing, setEditing] = useState(false);

    const [errors, setErrors] = useState({
        from : "",
        to : "",
        price : "",
        price_fret : "",
    });


    //Recuperation d'une facture
    const fetchTrajet = async id => {
        try{
            const { from, to, price, price_fret, image, newest } = await TrajetAPI.find(id);
            setTrajet({ from, to, price, price_fret, image, newest });
            setLoading(false);
        }catch(error){
            toast.error("Impossible de charger le trajet démandé !");
            navigate("/admin/trajets");
        }
    };


    useEffect(() => {
        setEditing(true);
        fetchTrajet(trajetId);
    }, [trajetId]);


    //Gestion de changement des inputs dans le formulaire
    const handleChange = ({currentTarget}) => {
        const {name, value} = currentTarget;
        setTrajet({...trajet, [name] : value})
    };

    const handleCheck = () => {
        setChecked(!checked);
        if (!checked === true) {
            setTrajet({...trajet, newest : 1});
        }else{
            setTrajet({...trajet, newest : 0});
        }

    };

    function handlePicInput(event){
        let image = event.target.files;
        //let fd = new FormData()
        //fd.append("image", image);
        setTrajet({...trajet, image : image[0]});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await TrajetAPI.updateTrajet(trajetId, trajet);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Trajet modifié avec succès");
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire de la modification du trajet !");
            }
        }
        //console.log(trajet);
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    {loading && <FormContentLoader />}
                    {!loading && <form onSubmit={handleSubmit} encType="multipart/form-data">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Modifier un trajet</h5>

                                        <Field
                                            name="from"
                                            error={errors.from}
                                            value={trajet.from}
                                            onChange={handleChange}
                                            label="Provenance"
                                        />

                                        <Field
                                            name="to"
                                            error={errors.to}
                                            value={trajet.to}
                                            onChange={handleChange}
                                            label="Destination"
                                        />


                                        <Field
                                            name="price"
                                            error={errors.price}
                                            value={trajet.price}
                                            onChange={handleChange}
                                            label="Price du trajet"
                                            type="number"
                                        />

                                        <CheckBoxField
                                            name="actived"
                                            value={trajet.newest}
                                            label="Plus récente?"
                                            onChange={handleCheck}
                                            checked={trajet.newest ? "checked" : ""}
                                        />

                                        <input
                                            type="file"
                                            name="image"
                                            //value={trajet.image}
                                            label="Image (pour l'application mobile)"
                                            onChange={handlePicInput}
                                            className="form-control mb-3"
                                            multiple
                                        />

                                        <MyButton
                                            text="Modifier"
                                            onSubmit={handleSubmit}
                                            loading={showLoader}
                                            disabled={showLoader}
                                        />
                                        <Link to="/admin/trajets" className="btn btn-danger mx-2">Annuler</Link>
                                    </div>
                                </div>
                            </form>
                    }
                </div>
            </div>
        </div>
    );
};

export default EditTrajet;
